import * as yup from 'yup';
import { MAX_FILE_SIZE, SUPPORTED_FORMATS } from '@helpers/constants';
import { paymentTerm } from './add-payment.schema';
import { IDocumentMapping } from '@pages/order/order-individual/components/task-tab/post-shipment-docs/components/documents-tab';

const selectSchema = yup.object().shape({
  value: yup.string().required(),
  label: yup.string().required()
});

const categorySchema = yup.object().shape({
  category_name: yup.string().required(),
  category_value: yup.number().required()
});

const orderItemSchema = yup.object().shape({
  order_item_id: yup.string().required(),
  order_item_name: yup.string().required(),
  supplier_id: yup.string().optional().nullable()
});

const supplierSchema = yup.object().shape({
  supplier_id: yup.string().required(),
  supplier_name: yup.string().required()
});

/**
 * Document name will not be added in phase 1 hence commented, DO NOT DELETE beacuse it might be added again
 */

const generateSchema = (documentMapping: IDocumentMapping, documentKey: number) => {
  const schemaFields: { [key: string]: yup.AnySchema } = {
    document_id: yup.string().optional(),
    remarks: yup.string().optional(),
    document_type: selectSchema.required('Document Type is required'),
    // document_name: yup.string().trim().required('Document Name is required'),
    document_object: yup
      .mixed()
      .required('Document Object is required')
      .test('fileSize', 'File Size is too large', (value: any) => value.size <= MAX_FILE_SIZE)
      .test('fileType', 'Unsupported File Format', (value: any) =>
        SUPPORTED_FORMATS.includes(value.type)
      ),
    reason_for_update: yup.string().when(['document_object', 'edit_mode'], (val, schema) => {
      if (val?.length && val[0] && val[1]) {
        return yup.string().required('Reason for Upload is required');
      } else {
        return yup.string().optional().nullable();
      }
    })
  };
  Object.entries(documentMapping).forEach(([key, config]) => {
    if (config.required && config.documentKey === documentKey) {
      if (config.type === 'select') {
        if (config?.required) {
          schemaFields[key] = selectSchema.required(config.label + ' is required');
        } else {
          schemaFields[key] = selectSchema.optional().nullable();
        }
      } else if (config.type === 'text') {
        if (config?.validation?.type === 'number') {
          if (config?.required) {
            schemaFields[key] = yup
              .number()
              .required(config.label + ' is required')
              .typeError(config?.validation?.typeErrorMessage);
          } else {
            schemaFields[key] = yup
              .number()
              .typeError(config?.validation?.typeErrorMessage)
              .optional()
              .nullable();
          }
        } else {
          if (config?.required) {
            schemaFields[key] = yup.string().required(config.label + ' is required');
          } else {
            schemaFields[key] = yup.string().optional().nullable();
          }
        }
      } else if (config.type == 'date') {
        if (config?.required) {
          schemaFields[key] = yup.date().required(config.label + ' is required');
        } else {
          schemaFields[key] = yup.date().optional().nullable();
        }
      }
    }
  });

  return yup.object().shape(schemaFields);
};

export const addMappingDocumentValidation = (
  documentMapping: IDocumentMapping,
  documentKey: number
) => {
  return generateSchema(documentMapping, documentKey);
};

const addDocument = yup.object().shape({
  document_id: yup.string().optional(),
  document_description: yup.string().optional(),
  document_type: selectSchema.required('Document Type is required'),
  // document_name: yup.string().trim().required('Document Name is required'),
  document_object: yup
    .mixed()
    .required('Document Object is required')
    .test('fileSize', 'File Size is too large', (value: any) => value.size <= MAX_FILE_SIZE)
    .test('fileType', 'Unsupported File Format', (value: any) =>
      SUPPORTED_FORMATS.includes(value.type)
    ),
  reason_for_update: yup.string().when(['document_object', 'edit_mode'], (val, schema) => {
    if (val?.length && val[0] && val[1]) {
      return yup.string().required('Reason for Upload is required');
    } else {
      return yup.string().optional().nullable();
    }
  })
});

const addOtherDocument = yup.object().shape({
  type: yup.mixed().required('Document Type is required'),
  internal_tags: yup.array().of(selectSchema).min(1, 'Tags are required'),
  name: yup.string().when('type', (type, schema) => {
    return type[0].label.split(' ')[0] === 'Other'
      ? yup.string().required('Name is required')
      : yup.string().optional().nullable();
  }),
  document_type: yup.string().trim().optional().nullable(), //This will be deprecated
  remarks: yup.string().trim().optional().nullable(),
  supplier: yup.mixed().when('supplier_required', (val, schema) => {
    if (val?.length && val[0]) {
      return yup.mixed().required('Supplier is required');
    } else {
      return yup.mixed().optional().nullable();
    }
  }),
  order_item: yup.mixed().when('product_required', (val, schema) => {
    if (val?.length && val[0]) {
      return yup.mixed().required('Product is required');
    } else {
      return yup.mixed().optional().nullable();
    }
  }),
  order_document_id: yup.string().trim().optional().nullable(),
  product_required: yup.boolean().optional().nullable(),
  supplier_required: yup.boolean().optional().nullable(),
  edit_mode: yup.mixed().optional().nullable(),
  document_object: yup.mixed().when('edit_mode', (val, schema) => {
    if (val?.length && val[0]) {
      return yup.mixed().optional().nullable();
    } else {
      return yup
        .mixed()
        .required('Document is required')
        .test('fileSize', 'File Size is too large', (value: any) => value.size <= MAX_FILE_SIZE)
        .test('fileType', 'Unsupported File Format', (value: any) =>
          SUPPORTED_FORMATS.includes(value.type)
        );
    }
  }),
  reason_for_update: yup.string().when(['document_object', 'edit_mode'], (val, schema) => {
    if (val?.length && val[0] && val[1]) {
      return yup.string().required('Reason for Upload is required');
    } else {
      return yup.string().optional().nullable();
    }
  }),
  uploadType: yup.string().trim().optional().nullable(),
  selected_order_ids: yup.mixed().test({
    name: 'selected-orders-validation',
    test: function (value, context) {
      const uploadType = this.parent.uploadType;
      if (uploadType === 'finance' && (!Array.isArray(value) || value.length === 0)) {
        return this.createError({
          message: 'Order IDs are required for finance documents',
          path: 'selected_order_ids'
        });
      }
      return true;
    }
  })
});

const resolveInconsistencySchema = yup.object().shape({
  valid_document_type: yup.number().required('Document Type is required'),
  valid_document_object: yup.mixed().when('valid_document_type', (val, schema) => {
    if (val?.length && val[0] === 3) {
      return yup
        .mixed()
        .required('Document Object is required')
        .test('fileSize', 'File Size is too large', (value: any) => value.size <= MAX_FILE_SIZE)
        .test('fileType', 'Unsupported File Format', (value: any) =>
          SUPPORTED_FORMATS.includes(value.type)
        );
    } else {
      return yup.mixed().optional().nullable();
    }
  }),
  reason_for_update: yup.string().when('valid_document_type', (val, schema) => {
    if (val?.length && val[0] === 3) {
      return yup.string().required('Reason for Upload is required');
    } else {
      return yup.string().optional().nullable();
    }
  })
});

const piPaymentPaymentInfo = yup.object().shape({
  id: yup.number().optional().nullable(),
  payment_date: yup.date().optional().nullable(),
  payment_reference_number: yup.string().optional().nullable(),
  is_checked: yup.boolean().optional().nullable(),
  amount_adjusted: yup.number().when('is_checked', (isChecked, schema) => {
    return isChecked[0]
      ? yup.number().required('Amount Adjusted is required')
      : yup.number().optional().nullable();
  }),
  amount_paid: yup.number().optional().nullable()
});

const invoiceSchema = yup.object().shape({
  is_payment_mode: yup.mixed().nullable(),
  pi_invoice_details: yup.mixed().when('is_payment_mode', {
    is: (value: any) => {
      return value?.value === true;
    },
    then: () =>
      yup.object().shape({
        pi_document: yup
          .mixed()
          .required('Document is required')
          .typeError('Invalid document type, please provide a valid document'),
        pi_invoice_number: yup.string().required('PI Invoice Number is required'),
        pi_invoice_date: yup.date().required('PI Invoice Date is required'),
        pi_invoice_amount: yup.number().required('PI Invoice Amount is required'),
        pi_invoice_payment_terms: yup.string().required('PI Invoice Payment Terms are required'),
        pi_invoice_payment_info: yup
          .array()
          .of(piPaymentPaymentInfo)
          .required('PI Invoice Payment Info is required'),
        pi_child_invoice_gross_amount: yup.number().optional().nullable()
      }),
    otherwise: () =>
      yup.object().shape({
        pi_document: yup
          .mixed()
          .optional()
          .nullable()
          .typeError('Invalid document type, please provide a valid document'),
        pi_invoice_number: yup.string().optional().nullable(),
        pi_invoice_date: yup.date().optional().nullable(),
        pi_invoice_amount: yup.number().optional().nullable(),
        pi_invoice_payment_terms: yup.string().optional().nullable(),
        pi_invoice_payment_info: yup.array().of(piPaymentPaymentInfo).optional().nullable(),
        pi_child_invoice_gross_amount: yup.number().optional().nullable()
      })
  }),
  invoice_currency: selectSchema.typeError('Currency is required').required('Currency is required'),
  invoice_number: yup.string().required('Invoice Number is required'),
  invoice_date: yup.date().required('Invoice Date is required'),
  invoice_amount: yup
    .number()
    .typeError('Invoice Amount must be a number')
    .required('Invoice Amount is required'),
  invoice_gst: yup
    .number()
    .typeError('Invoice GST must be a number')
    .required('Invoice GST is required'),
  multiple_tax_invoice: yup.boolean().optional().nullable(),
  total_invoice_amount: yup
    .string()
    .required('Total Invoice Amount is Required')
    .when('pi_invoice_details.pi_remaining_invoice_amount', (piRemainingInvoiceAmount, schema) => {
      if (piRemainingInvoiceAmount[0] == null) {
        return yup.string().required('Total Invoice Amount is Required');
      } else {
        return yup
          .string()
          .required('Total Invoice Amount is Required')
          .when('multiple_tax_invoice', (multipleTaxInvoice) => {
            return multipleTaxInvoice[0]
              ? yup
                  .string()
                  .required('Total Invoice Amount is Required')
                  .test(
                    'totalInvoiceAmount',
                    'Total Invoice Amount must be less than or equal to PI Remaining Invoice Amount',
                    function (value) {
                      const { pi_invoice_details } = this.parent;
                      if (pi_invoice_details.pi_remaining_invoice_amount != null) {
                        return value <= pi_invoice_details.pi_remaining_invoice_amount;
                      }
                      return true;
                    }
                  )
              : yup
                  .string()
                  .required('Total Invoice Amount is Required')
                  .test(
                    'totalInvoiceAmount',
                    'Total Invoice Amount must be equal to PI Remaining Invoice Amount',
                    function (value) {
                      const { pi_invoice_details } = this.parent;
                      if (pi_invoice_details.pi_remaining_invoice_amount != null) {
                        return value == pi_invoice_details.pi_remaining_invoice_amount;
                      }
                      return true;
                    }
                  );
          });
      }
    }),
  amount_paid: yup.mixed().when('is_payment_mode', {
    is: (value: any) => value?.value === false,
    then: () => yup.string().optional().nullable(),
    otherwise: (schema) =>
      schema.when('multiple_tax_invoice', (multipleTaxInvoice, schema) => {
        return multipleTaxInvoice[0]
          ? yup
              .number()
              .typeError('Amount Paid must be a number')
              .required('Amount Paid is Required')
          : yup.number().optional().nullable();
      })
  }),
  tds: yup.mixed().when('is_payment_mode', {
    is: (value: any) => value?.value === false,
    then: () => yup.string().optional().nullable(),
    otherwise: (schema) =>
      schema.when('multiple_tax_invoice', (multipleTaxInvoice, schema) => {
        return multipleTaxInvoice[0]
          ? yup.number().required('TDS is Required').typeError('TDS must be a number')
          : yup.number().optional().nullable();
      })
  }),
  tcs: yup.mixed().when('is_payment_mode', {
    is: (value: any) => value?.value === false,
    then: () => yup.string().optional().nullable(),
    otherwise: (schema) =>
      schema.when('multiple_tax_invoice', (multipleTaxInvoice, schema) => {
        return multipleTaxInvoice[0]
          ? yup.number().required('TCS is Required').typeError('TCS must be a number')
          : yup.number().optional().nullable();
      })
  }),
  supplier_bank_detail: yup.mixed().required('Supplier Bank Detail is required'),
  payment_terms: yup.array().of(paymentTerm)
});

const orderInvoiceMappingSchema = yup.object().shape({
  order_ids_to_invoice_share_map: yup
    .array()
    .of(
      yup.object().shape({
        readable_order_id: yup.string().required('Order ID is required'),
        share: yup.number().typeError('Share must be a number').required('Share is required'),
        order_id: yup.string().required('UUID is required')
      })
    )
    .required('Mapping is required')
});

export {
  addDocument,
  addOtherDocument,
  resolveInconsistencySchema,
  invoiceSchema,
  orderInvoiceMappingSchema
};
